<template>
    <div class="">
                    


        <Dialog header="Seleccione el operador" v-model:visible="showOperatorModal" :style="{width: '50vw'}">

            <Button 
                v-for="(operator, index) in operators"
                :key="operator.id"
                :label="operator.name"
                :class="'col-3 mx-1 my-1 text-sm ' + classOpSel(operator.id)" 
                @click="selectedOperator = operator.id">
            </Button>
            <hr />
            
            <Button 
                v-for="(machine, index) in machines"
                :key="machine.id"
                :label="machine.name" :class="'col-3 mx-1 my-1  text-sm ' + classMaSel(machine.id)" 
                @click="selectedMachine = machine.id">
            </Button>


            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" @click="setOperator()" autofocus />
            </template>
        </Dialog>
    <div  class="col flex" v-if="order.status == 0 && !selectedOP"   >
                    
<!-- random button -->
<Button  @click="random()"
icon="pi pi-bolt"
        />
        <Button
        class="p-button-sm  p-button-outlined w-full uppercase p-button-danger  "

        
        @click="showOperatorModal = true"
      >
      <div>

          <i class="pi pi-user-plus"></i> Seleccionar Operador
        </div>
<div class="ml-2">

    <i class="pi pi-cog"></i> Seleccionar Maquinaría
</div>
      </Button> 
    </div>
    <div v-else>
        
        <Button
        class="p-button-sm p-button-primary p-button-outlined w-full uppercase "

       >

       <div>

<i class="pi pi-user"></i> {{ giveMeName(selectedOP) }}
</div>
<div class="ml-2">

<i class="pi pi-cog"></i> {{ giveMeMachineName(selectedMachine) }}
</div>


</Button>


    </div>

        </div>  

        </template> 

        <script>

        export default {
        name: 'SelOpForOtiOp',
        props: ['order', 'operation', 'operators', 'selectedOP', 'machines'],
        data() {
        return {
            showOperatorModal: false,
            selectedOperator: null,
            selectedMachine: null,
        }
        },
        methods: {
            random(){

                let random = Math.floor(Math.random() * this.operators.length)
                let randomMachine = Math.floor(Math.random() * this.machines.length)
                this.selectedOperator = this.operators[random].id
                this.selectedMachine = this.machines[randomMachine].id
                this.$emit('update', [this.selectedOperator, this.selectedMachine])

            },
            setOperator() {
                this.$emit('update', [this.selectedOperator, this.selectedMachine])
                this.showOperatorModal = false
            },
            closeBasic() {
                this.selectedOperator = null
                this.selectedMachine = null
                this.showOperatorModal = false
            },
            classOpSel(id) {
                if (id == this.selectedOperator) {
                    return 'p-button-success'
                } else {
                    return 'p-button-outlined '
                }
            },
            classMaSel(id) {
                if (id == this.selectedMachine) {
                    return 'p-button-success'
                } else {
                    return 'p-button-outlined '
                }
            },
            giveMeName(id){
                let name = ''
                this.operators.forEach(op => {
                    if(op.id == id){
                        name = op.name
                    }
                })
                return name
            },
            giveMeMachineName(id){
                let name = ''
                this.machines.forEach(op => {
                    if(op.id == id){
                        name = op.name
                    }
                })
                return name
            }
        }
        }
        </script>