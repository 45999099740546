<template>
  <div class="flex flex-wrap justify-content-start">

    <Dialog header="Finalizado de operación" v-model:visible="endModal" :style="{ width: '50vw' }" :modal="true">

      Cuantas piezas se han finalizado?

      <InputNumber v-model="endQty" :min="1" :max="this.order.quantity - this.operation.end_qty"
        :style="{ width: '100%' }" />

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="endModal = false" class="p-button-text" />
        <Button label="Confirmar" icon="pi pi-check" @click="statusTo(2)" autofocus />
      </template>
    </Dialog>

    <Dialog header="Seleccione el operador" v-model:visible="showOperatorModal" :style="{ width: '50vw' }">

      <Button v-for="(operator, index) in operators" :key="operator.id" :label="operator.name"
        :class="'col-3 mx-1 my-1 text-sm ' + classOpSel(operator.id)" @click="selectedOperator = operator.id">
      </Button>
      <hr />

      <Button v-for="(machine, index) in this.machines" :key="machine.id" :label="machine.name"
        :class="'col-3 mx-1 my-1  text-sm ' + classMaSel(machine.id)" @click="selectedMachine = machine.id">
      </Button>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="closeBasic" class="p-button-text" />
        <Button label="Confirmar" icon="pi pi-check" @click="setOperator()" autofocus
          :disabled="!this.selectedOperator" />
      </template>
    </Dialog>
    <div class="oti_action uppercase " v-if="operation.assigned">

      <Button :label="timer" :class="'p-button-sm   ' + classBtn" icon="pi pi-clock" />


    </div>

    <div v-if="operation.assigned" class="mr-1">
      <Button class="p-button-sm p-button-primary p-button-outlined w-full uppercase "
        :label="operation.operator_name ? operation.operator_name : operation.user_name" icon="pi pi-user" />
    </div>
    <div v-else class="mr-1">

      <Button class="p-button-sm  p-button-outlined w-full uppercase p-button-danger  " label="ASIGNAR"
        icon="pi pi-user-plus" @click="assignOperation(operation)" />
    </div>



    <div class="ml-auto flex">

 
 
          <div class="" v-if="operation.end_qty < order.quantity && operation.status == 2" >

            <Button class="p-button-sm  p-button-outlined  uppercase p-button-danger  mr-2 "  
              icon="pi pi-user-plus" @click="assignOperation(operation)" />

          </div>
          <div  v-if="operation.end_qty < order.quantity && operation.status == 2">
            <Button class="p-button-sm  uppercase p-button-success mr-1  " 
              icon="pi pi-play" @click="statusTo(1)" />

          </div>
 

 
      <div v-if="operation.status == 0">

        <Button class="p-button-sm  w-full uppercase p-button-success  " icon="pi pi-play" @click="statusTo(1)" />
      </div>
      <div v-if="operation.status == 1">

        <Button class="p-button-sm uppercase p-button-warning ml-1 " icon="pi pi-pause" @click="statusTo(3)" />

      </div>

      <div v-if="operation.status == 3 && operation.end_qty < parseFloat(order.quantity)">

        <Button class="p-button-sm uppercase p-button-success ml-1 " icon="pi pi-play" @click="statusTo(1)" />

      </div>
      <div v-if="operation.status !== 0">
        <PlanillaInspeccion :order="this.operation" :operators="this.operators" />
      </div>
      <div v-if="operation.end_qty < parseFloat(order.quantity)">

        <Button class="p-button-sm  uppercase p-button-danger ml-1" icon="pi pi-stop" @click="statusTo(2)"
          :disabled="!operation.user_id" />

      </div>


      <div class="p-badge p-badge-success ml-2">
        {{ operation.end_qty }} / {{ parseFloat(order.quantity) }}
      </div>

    </div>

  </div>
</template>
<script>
import moment from "moment";
import OtiService from "./../services/oti.service.js";
import PlanillaInspeccion from "./PlanillaInspeccion.vue";


export default {
  props: {
    machines: {},
    operation: {},
    userGroup: {},
    operators: {},
    order: {},
  },
  components: {
    PlanillaInspeccion,
  },
  emits: ["logChange"],
  data() {
    return {
      selectedMachine: null,
      notFullControl: true,
      actualStatus: this.operation.status,
      timer: "00:00:00",
      counter: { seconds: 0, timer: null },
      showOperatorModal: false,
      selectedOperator: null,
      endModal: false,
      endQty: this.order.quantity - this.operation.end_qty,
    };
  },

  created() {


  },
  mounted() {


    this.counter['seconds'] = this.operation.time ? this.operation.time : 0;
    const time = this._readableTimeFromSeconds(this.counter.seconds)
    this.timer = `${time.hours}:${time.minutes}:${time.seconds}`

    if (this.operation.status == 1) {
      this.startTimer()
    } else {

    }


  },
  watch: {
    actualStatus: function (newVal, oldVal) {
      if (newVal == 1) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    },
  },
  methods: {
    classOpSel(id) {
      if (id == this.selectedOperator) {
        return 'p-button-success'
      } else {
        return 'p-button-outlined '
      }
    },
    classMaSel(id) {
      if (id == this.selectedMachine) {
        return 'p-button-success'
      } else {
        return 'p-button-outlined '
      }
    },
    check_controls(data) {
      console.log('received on oti opertions', data)
      this.notFullControl = e
    },
    control() {
      alert('ACA DIAGLO CONTROL VARIASBLES')
      this.$emit('control', this.operation)
    },

    setOperator() {
      this.showOperatorModal = false;

      let data = {

        operator_id: this.selectedOperator,
        oti_id: this.order.id,
        operation_id: this.operation.operation_id,
        machine_id: this.selectedMachine,
      }


      OtiService.assignOperation(data).then((response) => {
        this.operation.assigned = true;
        this.operation.operator_name = response.data.operator_name;
        this.operation.user_name = response.data.user_name;
        this.operation.user_id = response.data.user_id;
        this.operation.operator_id = response.data.operator_id;
        this.operation.status = 0;
        this.operatoin.oti_id = response.data.oti_id;
        this.operation.machine_id = response.data.machine_id;


      });

    },
    assignOperation(id) {
      this.showOperatorModal = true;
    },
    _padNumber: number => (number > 9) ? number : "0" + number,
    _readableTimeFromSeconds: function (seconds) {
      const hours = 3600 > seconds ? 0 : parseInt(seconds / 3600, 10)
      return {
        hours: this._padNumber(hours),
        seconds: this._padNumber(seconds % 60),
        minutes: this._padNumber(parseInt(seconds / 60, 10) % 60),
      }
    },
    startTimer: function (timer) {

      this.counter.ticker = setInterval(() => {
        const time = this._readableTimeFromSeconds(++this.counter.seconds)

        this.timer = `${time.hours}:${time.minutes}:${time.seconds}`
      }, 1000)
    },
    stopTimer: function () {
      clearInterval(this.counter.ticker);
    },

    statusTo(val) {

      if (val == 2 && !this.endModal) {
        this.endModal = true;
        return
      }


      let data = {
        operation_id: this.operation.operation_id ? this.operation.operation_id : this.operation.id,
        oti_id: this.order.id,
        status: val,
        operator_id: this.operation.operator_id,

      };

      if (val == 2) {
        data.end_qty = this.endQty;
      }

      OtiService.updateOtiOperation(data)
        .then((res) => {
          if (res.status == "success") {
            this.operation.status = val;
            this.actualStatus = val;


            if (val == 2) {
              this.endModal = false;
              this.operation.end_qty = this.endQty + this.operation.end_qty;
              this.endQty = this.order.quantity - this.operation.end_qty;
            }
            // update operation with data
            this.operation = res.data;


            this.$emit("logChange", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });


      this.$emit("logChange", val);
    },
    init() {
      this.$emit("init");
    },
    finish() {
      this.$emit("finish");
    },
  },
  computed: {
    classBtn() {
      if (this.operation.status == 3) {
        return "p-button-warning";
      } else if (this.operation.status == 1) {
        return "p-button-success";
      } else if (this.operation.status == 2) {
        return "p-button-plain";
      } else {
        return "p-button-info";
      }
    },
    statusClass() {
      switch (this.actualStatus) {
        case 0:
          return "pi pi-exclamation-circle";
        case 1:
          return "pi pi-play";
        case 2:
          return "pi pi-pause";
        case 3:
          return "pi pi-star";
        default:
          return "pi pi-shopping-cart";
      }
    },
    statusName() {
      switch (value) {
        case 0:
          if (this.order.current_operator) {
            return " Sin Iniciar por " + this.order.operator_name;
          } else {
            return " Sin asignar";
          }
        case 1:
          return " En proceso";
        case 2:
          return " En pausa";
        case 3:
          return " En calidad";
        case 4:
          return " Aprobada";
        case 5:
          return " Desaprobada";
        case 6:
          return " En TORNO / EN COLA ";
        case 7:
          return " EN TORNO / EN PROCESO";

        default:
          return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oti_action {
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 5px;
  line-height: 19px;
  height: 35px;
  width: 120px;
  font-size: 16px;

  &>div i {
    font-size: 12px;
    line-height: 35px;
  }

  &.st_bg_color_7 {
    border: 1px solid #b71d1d71;
    background-color: #f808089a;

    &>div i {
      color: #b71d1d71;
    }
  }

  &.st_bg_color_3 {
    border: 1px solid #44b73a71;
    background-color: #44b73a71;

    &>div i {
      color: #44b73a;
    }
  }

  &.st_bg_color_2 {
    border: 1px solid #ff99005b;
    background-color: #ff99005b;

    &>div i {
      color: #ff9800;
    }
  }

  &.st_bg_color_1 {
    border: 1px solid #1c96c256;
    background-color: #1c96c256;

    &>div i {
      color: #0b3b5a;
    }
  }

  &.st_bg_color_0 {
    border: 1px solid rgba(28, 150, 194, 0.808);
    background-color: #2ce4d49a;

    &>div i {
      color: #000000;
    }
  }

  &.st_bg_color_4 {
    background-color: #d8d8d89a;

    &>div i {
      color: #807f7f;
    }
  }

  &.st_bg_color_5 {
    border: 1px solid rgb(36, 64, 108);
    background-color: rgb(79, 143, 247);
    color: #fff;

    &>div i {
      color: #fff;
    }
  }
}
</style>
