    <template>
  <div>
    <div class="col-12" v-if="loaded">
      <!-- <div class="m-2">
        <Button class="ml-2" label="VOLVER" @click="this.$router.go(-1)" />
      </div> -->
      <div class="card" v-if="this.loaded">
        <div class="flex justify-content-between flex-wrap">
          <div>
            <!-- <h1>{{ otiName(order.id) }}</h1> -->
            <h1>

              ORDEN <span style="color:darkslategray">{{ order.op.oc_number }}</span> - <span style="color:#d45c00">{{ order.id }}</span>
            </h1>
          </div>
          <div class="" v-if="order && order.pieza && order.pieza.plane">
            <Button
              class="p-button-danger ml-2"
              label="PLANO"
              icon="pi pi-file-pdf"
              @click="showPlane(order.pieza.plane.file)"
            />
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col-6">
              <label for="detail">CLIENTE</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.client_name }}
              </span>
            </div>
            <div class="field col-6">
              <label for="detail">ORDEN DE COMPRA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.op.oc_number }}
              </span>
            </div>
            <div class="field col-4">
              <label for="detail">CÓDIGO</label>
              <span id="detail" type="text" class="inputfield w-full font-bold ">
                {{ order.pieza.code }} 
              </span>
            </div>
            <div class="field col-6">
              <label for="detail">PIEZA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.piece_name }}
              </span>
            </div>

            <div class="field col-2">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.quantity }}
              </span>
            </div>
          </div>


          <div class="formgrid grid">
            <div class="field col-12">
              <label for="detail">MATERIALES</label>

              <div>

                <div v-for="mat in order.materials">
                  
                  <i class="pi pi-circle pr-2" style="color: #000"></i>
                 <strong>

                  {{ mat.quantity }}

                  {{ mat.unit_name ? mat.unit_name : 'UN'
                   }}
                   </strong> de
                  {{ mat.material_name }}   
                  
                  <span class="p-badge p-component p-button  mr-2"><i class="pi pi-qrcode mx-2"></i> {{  mat.material.material_code }}</span>

                  <span class="p-badge p-component p-button p-button-warning  mr-2"><i class="pi pi-star mx-2"></i> {{  mat.material.cert_nro 
                  ? mat.material.cert_nro : 'SIN CERTIFICADO' }}</span>
                  
                  <span class="p-badge p-component p-button p-button-secondary  mr-2"><i class="pi pi-truck mx-2"></i> {{  mat.material.rto_nro 
                  ? mat.material.rto_nro : '-' }}</span>
                  
                  <span class="p-badge p-component p-button p-button-info  mr-2"><i class="pi pi-map-marker mx-2"></i> {{  mat.material.location 
                  ? mat.material.location : '-' }}</span>
                </div>

              </div>

            </div>

            <div class="field col">
              <!-- <label for="detail">INSUMOS</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.supplies }}
              </span> -->

              
            </div>

            </div>




        </div>
           </div>
        <div class="">
          
          <div class="grid ">
            
            <div class=" col-6">
                <h5>Operaciones</h5>
              
                <div class="card"> 

                    <div v-for="(operation, index) in this.order.operations" :key="index" class="flex-wrap justify-content-between mb-2 w-50">
                      <div class="uppercase ">

<strong> {{ operation.operation_name }}</strong>
<!-- 
<div v-if="operation.status == 1">
  <ProgressBar mode="indeterminate" style="height: .5em" />
</div> -->

</div>


                      <div class="flex-wrap">
                        <OtiOperationActions :operation="operation" :operators="operators" @logChange="logChange" 
                        :machines="machines"
                        
                        :order="order"/>

                        <hr/>
                      </div>



                      

                    </div>
                </div>


                <div class=" grid" v-if="this.order.operations">
                  <div class="col-12 " v-if="(this.shortStatus(order.status) == 2 && order.db_status !== 20)">
                    <div class="card"> 
                      <Button class="p-button-success ml-2 w-full" label="LISTO PARA ENTREGAR" icon="pi pi-truck" @click="this.toSend()" />
                    </div>
                  
                  </div>
                  <div class="col-12 " v-if="order.db_status  == 20">
                    <div class="card"> 
                      <Button class="p-button-info ml-2 w-full" label="EN STOCK" icon="pi pi-box" disabled />
                    </div>
                  
                  </div>

<div class=" col text-center">
<h5>
  TIEMPO TOTAL TRANSCURRIDO:
</h5>
<div class="">

  <div class="p-badge p-badge-lg">
    
    {{ totalTime() }}
  </div>
</div>
</div>

<div class=" col text-center">
<h5>
  TIEMPO PROMEDIO POR PIEZA 
</h5>
<div class="">

  <div class="p-badge p-badge-lg">
    {{ totalTimeByPiece() }}
  </div>
</div>

</div>

</div>



                </div>
            <div class=" col-6">
                <h5>Historial</h5>

                <div class="card"> 

                  <Timeline :value="events1">
                <template #opposite="slotProps">
                  {{ slotProps.item.status }} - 
                    <small class="p-text-secondary">{{slotProps.item.date}}</small>
                    <p v-if="slotProps.item.status !== 11 &&  slotProps.item.status !== 10  &&  slotProps.item.status !== 0">
                      <i class="pi pi-user"></i>
                      {{ giveMeName( slotProps.item.operator_id, 'usuario' )  }}
                    </p>
                    <p v-else style="color: #d45c00;">
                      <i class="pi pi-user"></i>
                      {{ slotProps.item.user_name }}
                    </p>
                </template><template #marker="slotProps">
                    <span class="custom-marker shadow-2" :style="{backgroundColor: this.statusColor(slotProps.item.status) }">
                      
                      <i class="pi pi-user-plus" v-if="slotProps.item.status == 0"></i>
                      <i class="pi pi-play" v-if="slotProps.item.status == 1"></i>
                      <i class="pi pi-check" v-if="slotProps.item.status == 2"></i>
                      <i class="pi pi-pause" v-if="slotProps.item.status == 3"></i>

                      <i class="pi pi-star" v-if="slotProps.item.status == 8"></i>
                      <i class="pi pi-plus" v-if="slotProps.item.status == 10"></i>
                    </span>
                </template>

                <template #content="slotProps">
                    <!-- {{this.statusName[slotProps.item.status]}} -  -->
                    <div style="text-transform: uppercase; font-weight: bolder;">

                      {{slotProps.item.opn}} 
                    </div>
                    <div v-html="slotProps.item.comment"></div>
                    
                    
                </template>
              </Timeline>
                </div>

                </div>
            </div>
        </div>
   
    </div>
  </div>
</template>

<script>
import OtiClock from "./../../components/OtiClock.vue";
import CrudService from "./../../services/crud.service.js";
import OperatorService from "./../../services/operator.service.js";
import SelOpForOtiOp from "./../../components/SelOpForOtiOp.vue";
import otiService from "../../services/oti.service";
import OtiOperationActions from "./../../components/OtiOperationActions.vue";

export default {
  components: {
    OtiClock,
    SelOpForOtiOp,
    OtiOperationActions
  },
  data() {
    return {
      loaded: false,
      order: [],
      machines: [],
      clients: [],
      users: [],
      operators: [],
      operation: "",
      opforop: [],
      operaciones: [],
      materiales: [],
      statusName: 
[ "asigna", "inicia/reanuda", "finaliza", "pausa" ],
      
    
      events1: [
                {status: ' creada orden de producción a partir de la orden de compra ##### ', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg'},
                {status: 'se asigno utilizar estos materiales #####', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7'},
                {status: 'se asigno utilizar estos insumos #####', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800'},
                {status: 'se asigno la operacion #### al operador ####', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B'}
            ],
      selectedMaterial: [],
      filteredMaterials: [],
    };
  },
  created() {},
  mounted() {
    this.getOrder();
    this.getOperators();
    
  },
  computed: {},
  methods: {
    toSend() {
      
      this.loading = true;

      let data = {
                order_id: this.order.id,
                status: 20,
                oti_id: this.order.id,
            };

            this.order.status = 20
            this.order.db_status = 20

            otiService.toSend(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.loading = false;
            })

    },
    shortStatus(status) {
      let v = null;
      // check if status is an array
      if (Array.isArray(status)) {
        
        // check if all status are the same
        if (status.every((val, i, arr) => val === arr[0])) {
          v = status[0];
        } else {
          v = 1;
        }
        
      } else {
        // if it is not, return the status
        v = status;
      }

      return v
      switch (v) {
        case 0:
          return "Pendiente";
        case 1:
          return "En Proceso";
        case 2:
          return "Finalizado";
        case 3:
          return "Pausada";
        case 4:
          return "Cancelado";
        case 5:
          return "Rechazado";
        case 6:
          return "Aprobado";
        case 7:
          return "En Espera";
        case 8:
          return "En Espera";
        case 9:
          return "En Espera";
        case 10:
          return "En Espera";
        default:
            return "Pendiente";

      }


          

    },
    statusColor(status) {
      switch (status) {
        case 0:
          return "#9C27B0";
          break;
        case 1:
          return "#498205";
        case 2:
          return "#003b5d";
        case 3:
          return "#ffaa44";
        case 4:
          return "var(--danger)";
        case 8:
          return "#00a5b0";
        case 10:
          return "#0078d4";
        default:
          return "#fff";
      }
    },
    _readableTimeFromSeconds(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds - hours * 3600) / 60);
      let secs = seconds - hours * 3600 - minutes * 60;
      return `${hours}h ${minutes}m ${secs.toFixed(0)}s`;
    },
    totalTimeByPiece() {
      let total = 0;
      Object.keys(this.order.operations).forEach((key) => {
        if(this.order.operations[key].time){
          total += this.order.operations[key].time;
        } 
      });
      //return total //this._readableTimeFromSeconds(total / this.order.quantity);
      total = total / this.order.quantity;
      // two decimals
      total = Math.round(total * 100) / 100;
      return this._readableTimeFromSeconds(total);
    },
    totalTime() {
      
      console.log(this.order.operations) 

      let total = 0;

      Object.keys(this.order.operations).forEach((key) => {
        if(this.order.operations[key].time){
          total += this.order.operations[key].time;
        } 
      });

      return this._readableTimeFromSeconds(total);
    },
    logChange(data){
      
      console.log(data)
    
    },
    setParams() { 

      let params = {
        order: this.order,
        materials: this.selectedMaterial,
        primas: this.order.pieza.formula.materiales,
        operations: this.opforop,
      };

      otiService.setParams(params).then(
        (response) => {
          console.log(response);
          this.$router.push({ name: "orders" });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    updateOperation(e, i) {
      console.log(e, i);

      //        this.order.pieza.operaciones[i].operator_id = e.operator_id

      this.opforop[i] = e;
    },
    getOperators() {
      OperatorService.getOperators().then((response) => {
        this.operators = response;
      });
    },
    otiName(id) {
      // str pad left 0000
      return (
        "#" +
        ("000" + this.order.production_id).slice(-3) +
        "-" +
        ("0000" + id).slice(-4)
      );
    },

    getOrder() {
      //CrudService.getCRUD("cliente").then((data) => (this.clients = data));
      CrudService.getCRUD("api/operarios").then((data) => (this.users = data));
      CrudService.getCRUD('api/machines').then(
      (data) => (this.machines = data)
    );
      CrudService.getCRUD("api/materiales").then(
        (data) => (this.materiales = data)
      );
      console.log(this.$route.params.id)
      CrudService.getCRUD("api/orden/" + this.$route.params.id)
        .then((data) => {
          this.order = data.oti;
          this.events1 = data.events
          //this.selectedMaterial = data.selMats;
        })
        .then(() => {
          this.loaded = true;
        });
    },
    searchMaterials(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredMaterials = [...this.materiales];
        } else {
          this.filteredMaterials = this.materiales.filter((prod) => {
            return prod.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    giveMeName(id, table) {
      if (table == "cliente") {
        let exist = this.clients.find((client) => client.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "maquinaria") {
        let exist = this.machines.find((machine) => machine.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "usuario") {
        let exist = this.users.find((user) => user.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }

      if (table == "material") {
        let exist = this.materials.find((material) => material.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        }
      }
    },
  },
};
</script>

<style scoped>
.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}
</style>

<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

::v-deep(.p-timeline-event-content),
::v-deep(.p-timeline-event-opposite) {
    line-height: 1;
}

@media screen and (max-width: 960px) {
    ::v-deep(.customized-timeline) {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;
                
                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
}
</style>